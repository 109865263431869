import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import EmailEditor from "react-email-editor";

import sample from "./sample.json";

import { fetchTemplates, fetchAppDynamicsFields, sendThemeTest } from "thunks";
import {
  setStep,
  setCurrentNavPage,
  setTTPDialogCustomData,
  setNavigateBackUrl,
  setCurrentDndTheme,
} from "actions";
import { STEPS } from "Common";

import _ from "i18n";
import DIALOG_TYPES from "./../../constants/TTPDialog";
import {
  DRAG_DROP_ORIENTATION_OPTIONS,
  DRAG_DROP_THEME_TYPE_OPTIONS,
  DRAG_DROP_THEME_COLOR_OPTIONS,
} from "../../config/Common.js";
import ImageSelector from "./gallery/ImageSelector";

import { generateBlocs, onError, onSuccess } from "utils";
import { UNLAYER_PROJECT_ID } from "Config";
import {
  CAMPAIGN_DYNAMIC_FIELDS,
  DYNAMIC_FIELDS,
} from "../../constants/dynamicFields";
import {
  fieldToSpecialLinks,
  getMergedFields,
  getMergedOneFields,
} from "../../services/mapper";
import SaveThemeForm from "./SaveThemeForm";
import SendTestForm from "../common/SendTestForm";
import { prepareHtml } from "../../services/block/theme";
import ActionBar from "../campaign/create/ActionBar";
import { SVG_VALID_ICON } from "../../services/svgIcones";
import Loader from "react-loaders";
import {
  setCurrentCampaignTheme,
  setThemeIsSaved,
} from "../../actions/actions";
import NotFound from "../notFound/NotFound";
import { S3_FOLDER_URL_PROD } from "../../config";
import SubjectBox from "../campaign/text/SubjectBox";
import { CheckIcon, CopyIcon, X } from "lucide-react";
import { ucFirst } from "../../services/common";

@connect((store) => {
  return {
    lng: store.params.lng,
    currentTheme: store.themes.currentDndTheme,
    currentTemplate: store.themes.newsletter.currentTemplate,
    fetching: store.themes.list.fetching,
    content: store.themes.content,
    images: store.medias.items,
    blocs: store.themes.newsletter.blocks,
    themes: store.themes.list.items,
    base: store.themes.newsletter.base,
    updating: store.themes.updating,
    isSaved: store.themes.isSaved,
    templates: store.themes.list.items,
    fields: {
      RECIPIENT: {
        RECIPIENT: store.params.dynamicsFields.event.list?.RECIPIENT || [],
      },
      SURVEY: { SURVEY: store.params.dynamicsFields.event.list?.SURVEY || [] },
      WTB: store.params.dynamicsFields.wtb.list || [],
      EVENT: store.params.dynamicsFields.event.list || [],
    },
    isEventFetched: store.params.dynamicsFields.event.fetched,
  };
})
export default class DragDropEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: "Untitled",
      content: "",
      design: "",
      language: "",
      object: "",
      isPublic: 1,
      category: null,
      isDesignUpdated: false,
      main: 0,
      gdprThemeId: null,
      showImageGallery: false,
      done: null,
      images: [],
      generatedBlocs: [],
      type: "DRAG_AND_DROP",
      editorHasError: false,
      editorLoaded: false,
      themeCategory: "",
      activeInput: "editor",
      isDynamicFieldsPopupOpen: false,
      copiedItem: "",
    };

    this.minHeight = "calc(" + window.innerHeight + "px - 4rem)";

    this.toggleTestForm = () =>
      this.setState({ showTestForm: !this.state.showTestForm });

    const { dispatch, currentTheme, base, blocs, inCreateCampaign, childRef} =
      props;
    const { params } = this.props.match || {};
    if (!inCreateCampaign) {
      dispatch(setCurrentNavPage("CREATE_CAMPAIGN"));
      dispatch(setStep(STEPS["MANAGE_THEME"]));
    }
    dispatch(fetchAppDynamicsFields("EVENT"));
    // dispatch(fetchAppDynamicsFields("WTB"));

    const id = params?.templateId;
    if (id) {
      dispatch(fetchTemplates({ id })).then((res) => {
        if (
          (!res.value.data && !res.value.data.data) ||
          !res.value.data.data.length
        ) {
          return;
        }
        let {
          id,
          name,
          content,
          design,
          language,
          object,
          isPublic,
          main,
          gdprThemeId,
          type,
          targetApp,
          organization,
          themeCategory,
        } = res.value.data.data[0];
        this.setState({
          id,
          name,
          content,
          design,
          language,
          object,
          isPublic,
          main,
          gdprThemeId,
          type,
          targetApp,
          organization,
          themeCategory,
        });
        dispatch(setThemeIsSaved(true));
        this.loadDesign(design);
      });
    } else if (currentTheme) {
      let {
        id,
        name,
        content,
        design,
        language,
        object,
        isPublic,
        main,
        gdprThemeId,
        type,
        targetApp,
        organization,
        themeCategory,
      } = currentTheme;
      this.state = {
        ...this.state,
        id,
        name: name || "Untitled",
        content,
        design,
        language,
        object,
        isPublic,
        main,
        gdprThemeId,
        type: type || "DRAG_AND_DROP",
        targetApp: targetApp || "EMAILING",
        organization,
        themeCategory,
      };
      if (!inCreateCampaign && id) {
        dispatch(setThemeIsSaved(true));
      }
    } else {
      this.state = {
        ...this.state,
        type:
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.type,
      };
    }
  }

  componentDidMount() {
    const { base, blocs, inCreateCampaign, childRef, isUaNewsletter } =
      this.props;

    if (base !== "TEMPLATE" && blocs && blocs.length > 0) {
      let generatedBlocs = generateBlocs(blocs, false, true);
      this.setState({ generatedBlocs });
    }

    if (inCreateCampaign && childRef && !isUaNewsletter) {
      childRef({
        primaryAction: this.toggleSaveForm,
        secondaryAction: this.proceedToCampaignCreation,
        openDynamicFieldsAction: () => this.openDynamicFieldsAction(),
        close: this.confirmCloseEditor,
        isSaved: this.isSaved,
        isUpdating: this.isUpdating,
      });
      this.props.triggerRender && this.props.triggerRender();
    }
  
  }
  
  handleCopy = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    this.setState({ ...this.state, copiedItem: text });
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  handleClose = () => {
    this.setState({ ...this.state, isDynamicFieldsPopupOpen: false });
  };

  openDynamicFieldsAction = () => {
    this.setState({
      ...this.state,
      isDynamicFieldsPopupOpen: !this.state.isDynamicFieldsPopupOpen,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updating } = this.props;
    if (updating !== prevProps.updating) {
      this.props.triggerRender && this.props.triggerRender();
    }
  }

  componentWillUnmount() {
    const { dispatch, inCreateCampaign } = this.props;
    if (!inCreateCampaign) {
      dispatch(setStep(null));
      dispatch(setNavigateBackUrl(null));
      document.body.classList.remove("bg-d8");
      dispatch(setThemeIsSaved(false));
    }
  }

  confirmCloseEditor = () => {
    const { dispatch } = this.props;
    if (this.state.isDesignUpdated) {
      dispatch(
        setTTPDialogCustomData({
          approvedAction: this.closeEditor,
          message: _(
            "Once you close your changes will be lost. Do you really want to continue?",
          ),
          type: DIALOG_TYPES["ALERT"],
        }),
      );
    } else {
      this.closeEditor();
    }
  };

  closeEditor = () => {
    const { dispatch, history, inCreateCampaign } = this.props;
    dispatch(setStep(null));
    window.parent.postMessage("CLOSE_VIEWER", "*");
    if (inCreateCampaign) {
      history.push("/campaign/selector");
    } else {
      history.push("/templates");
    }
  };

  translateAndUppercase = (option) => {
    option.label = _(option.label).toUpperCase();
    return option;
  };

  toggleImageGallery = () => {
    this.setState({ showImageGallery: false });
  };

  sendTest = (recipients) => {
    const { id, dispatch } = this.props;
    let editor = this.editor || unlayer;

    editor.exportHtml(({ html }) => {
      let data = { id, html: prepareHtml(html), emails: recipients };

      dispatch(sendThemeTest(data)).then(
        (res) => {
          this.toggleTestForm();
          onSuccess(res);
        },
        (err) => onError(err),
      );
    });
  };

  toggleSaveForm = (theme = null) => {
    const { showSaveForm } = this.state;
    if (theme) {
      this.setState({ ...theme, isDesignUpdated: false });
      this.props.triggerRender && this.props.triggerRender();
    }
    this.setState({ showSaveForm: !showSaveForm });
  };

  proceedToCampaignCreation = () => {
    const { dispatch, currentTheme } = this.props;
    let editor = this.editor || unlayer;
    editor.exportHtml(({ html, design }) => {
      let theme = { ...currentTheme, design, content: html };
      dispatch(setCurrentDndTheme(theme));
      dispatch(setCurrentCampaignTheme(theme));
      const count = (html.match(/<a[^>]*href=["']["']/g) || []).length;
      if (count > 0) {
        dispatch(
          setTTPDialogCustomData({
            abortAction: () => this.props.proceed(),
            message:
              _("You have") +
              " " +
              count +
              " " +
              _("empty link(s) in your model") +
              "!",
            proceedBtnLabel: _("No, fill in the links"),
            abortBtnLabel: _("yesContinue"),
            title: "Confirmation",
            type: DIALOG_TYPES["SUCCESS"],
          }),
        );
      } else {
        this.props.proceed();
      }
    });
  };

  isSaved = () => {
    return this.props.isSaved;
  };

  isUpdating = () => {
    return this.props.updating;
  };

  onAction = (actionCode) => {
    if (this[actionCode]) this[actionCode]();
  };

  getTranslationLng = () => {
    const { lng, currentTheme, currentTemplate } = this.props;
    let currentLanguage = "all";
    if (currentTemplate && currentTemplate.language) {
      currentLanguage = currentTemplate.language;
    } else if (currentTheme && currentTheme.language) {
      currentLanguage = currentTheme.language;
    }

    return currentLanguage === "all" ? lng : currentLanguage;
  };

  loadDesign = (design) => {
    let editor = this.editor || unlayer;
    this.props.triggerRender && this.props.triggerRender();
    try {
      if (design && (typeof design === "string" || design instanceof String)) {
        design = JSON.parse(design);
      }
      editor.loadDesign(design);
    } catch (e) {
      console.warn(e);
      editor.loadDesign(sample);
    }
  };

  render() {
    const {
      lng,
      fetching,
      fields,
      updating,
      inCreateCampaign,
      isSaved,
      isEventFetched,
      isUaNewsletter,
    } = this.props;
    const {
      name,
      showImageGallery,
      done,
      type,
      showSaveForm,
      showTestForm,
      editorHasError,
      editorLoaded,
    } = this.state;
    const Event = getMergedFields(fields.EVENT, "EVENT", lng);
    const Recipient = isEventFetched
      ? getMergedOneFields(fields.RECIPIENT, lng)
      : [];
    const Survey = isEventFetched ? getMergedOneFields(fields.SURVEY, lng) : [];
    const Wtb = getMergedFields(fields.WTB, "WTB", lng);
    const EventLinks = fieldToSpecialLinks(fields.EVENT, "EVENT", lng);
    const SurveyLinks = isEventFetched
      ? fieldToSpecialLinks(fields.SURVEY, "SURVEY", lng)
      : [];
    const WtbLinks = fieldToSpecialLinks(fields.WTB, "WTB", lng);

    let translationLng = this.getTranslationLng();
    let options = {
      display: "email",
      mergeTags: [
        Event,
        Recipient,
        Survey,
        Wtb,
        { name: _("sending date"), value: "{{SENT.DATE}}" },
      ].concat(
        type === "WRAPPER"
          ? [
              { name: _("subject"), value: "{{SUBJECT}}" },
              { name: _("body"), value: "{{BODY}}" },
            ]
          : [],
      ),
      translations: {
        "en-US": {
          "buttons.upload_image": "Select Image",
        },
        "fr-FR": {
          "buttons.upload_image": "Sélectionner une image",
        },
      },
      specialLinks: [
        {
          name: _("unsubscribe"),
          href: "{{UNSUBSCRIBE_URL}}",
        },
        EventLinks,
        SurveyLinks,
        WtbLinks,
      ],
      features: {
        textEditor: {
          fontSizes: [
            "8px",
            "9px",
            "10px",
            "11px",
            "12px",
            "13px",
            "14px",
            "15px",
            "16px",
            "17px",
            "18px",
            "19px",
            "20px",
            "21px",
            "22px",
            "23px",
            "24px",
            "25px",
            "26px",
            "27px",
            "28px",
            "29px",
            "30px",
            "31px",
            "32px",
            "33px",
            "34px",
            "35px",
            "36px",
            "37px",
            "38px",
            "39px",
            "40px",
            "41px",
            "42px",
            "43px",
            "44px",
            "45px",
            "46px",
            "47px",
            "48px",
            "49px",
            "50px",
            "51px",
            "52px",
            "53px",
            "54px",
            "55px",
            "56px",
            "57px",
            "58px",
            "59px",
            "60px",
            "61px",
            "62px",
            "63px",
            "64px",
            "65px",
            "66px",
            "67px",
            "68px",
            "69px",
            "70px",
            "71px",
            "72px",
          ],
        },
      },
      fonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            label: "Roboto",
            value: "'Roboto', sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Roboto",
          },
          {
            label: "Poppins",
            value: "'Poppins', sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Poppins",
          },
          {
            label: "Oswald",
            value: "'Oswald', sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Oswald",
          },
          {
            label: "Bebas",
            value: "'Bebas', cursive",
            url: "https://fonts.googleapis.com/css2?family=Bebas",
          },
          {
            label: "Museo",
            value: "'Museo', cursive",
            url: "https://fonts.googleapis.com/css2?family=Museo",
          },
        ],
      },
    };

    const tools = {
      "custom#footer": {
        data: {
          now: new Date().getUTCFullYear(),
          iconsBaseUrl: `${S3_FOLDER_URL_PROD}/emailing/assets/social`,
          unsubscribe: _("unsubscribe", translationLng),
          web_version: _("web_version", translationLng),
        },
        properties: {
          orientation: {
            editor: {
              data: {
                options: DRAG_DROP_ORIENTATION_OPTIONS.map(
                  this.translateAndUppercase,
                ),
              },
            },
          },
          themeForm: {
            editor: {
              data: {
                options: DRAG_DROP_THEME_TYPE_OPTIONS.map(
                  this.translateAndUppercase,
                ),
              },
            },
          },
          themeColor: {
            editor: {
              data: {
                options: DRAG_DROP_THEME_COLOR_OPTIONS.map(
                  this.translateAndUppercase,
                ),
              },
            },
          },
        },
      },
    };

    return (
      <Fragment>
        <div className={`drag-drop-editor`}>
          {!inCreateCampaign && !isUaNewsletter && (
            <ActionBar
              icon={"DRAG_DROP"}
              title={"Drag&Drop editor"}
              name={name}
              currentStepIndex={0}
              steps={[
                {
                  actions: [
                    {
                      label: "{{}}",
                      cssClass: "success",
                      key: "openDynamicFieldsAction",
                    },
                    {
                      label: "save",
                      cssClass: "primary",
                      key: "toggleSaveForm",
                      icon: SVG_VALID_ICON,
                    },
                  ],
                },
              ]}
              onAction={this.onAction}
              onClose={this.confirmCloseEditor}
              isSaved={isSaved}
              updating={updating}
            />
          )}
          {fetching && (
            <div className="drag-drop-editor__overlay">
              <Loader type="line-spin-fade-loader" />
            </div>
          )}

          {!fetching &&
            isEventFetched &&
            (editorHasError ? (
              <NotFound text="errorOccured" />
            ) : (
              <>
                {this.state.isDynamicFieldsPopupOpen && (
                  <div
                    className="callout"
                    style={{
                      position: "absolute",
                      right: inCreateCampaign ? "27rem" : "15rem",
                      marginTop: inCreateCampaign ? "0.2rem" : "4.2rem",
                      width: "638px",
                      zIndex: 10,
                      overflowY: "auto",
                      maxHeight: "700px",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="grid-x grid-padding-x align-justify align-middle">
                      <div
                        className="cell small-11"
                        style={{
                          fontWeight: "500",
                          color: "firebrick",
                        }}
                      >
                        <span style={{ fontSize: "16px" }}>
                          {_("dynamic_fields")}
                        </span>
                      </div>
                      <div className="cell small-3 text-right">
                        <button
                          className="close-button"
                          aria-label="Close alert"
                          type="button"
                          onClick={this.handleClose}
                        >
                          <span aria-hidden="true">
                            <X size={16} />
                          </span>
                        </button>
                      </div>
                    </div>
                    {isEventFetched &&
                      !isUaNewsletter &&
                      Object.entries(fields).map(([field, value]) => {
                        return Object.entries(value).map(
                          ([category, fields], categoryIndex) =>
                            field !== "RECIPIENT" &&
                            field !== "SURVEY" && (
                              <div key={categoryIndex} className="m-t-xs">
                                <div
                                  className="row m-l-m"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#28394C",
                                  }}
                                >
                                  {category.replace("_", " ")}
                                </div>
                                {fields.map((field, fieldIndex) => (
                                  <div
                                    key={fieldIndex}
                                    className="row grid-x grid-padding-x align-middle margin-bottom-1 m-l-xs"
                                    style={{
                                      fontWeight: "500",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.handleCopy(field["code"])
                                    }
                                  >
                                    <div className="cell small-2 p-l-l">
                                      <div
                                        onClick={() =>
                                          this.handleCopy(field["code"])
                                        }
                                      >
                                        <button>
                                          <div
                                            className="flex text-gray-600"
                                            style={{
                                              marginLeft: "5px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {this.state.copiedItem ===
                                            field.code ? (
                                              <CheckIcon
                                                width={20}
                                                height={20}
                                                viewBox="-1 -4 25 25"
                                                stroke={"grey"}
                                              />
                                            ) : (
                                              <CopyIcon
                                                width={20}
                                                height={20}
                                                viewBox="-4 -4 28 28"
                                                stroke={"grey"}
                                              />
                                            )}
                                            <span
                                              style={{
                                                position: "absolute",
                                                margin: "6px",
                                                fontSize: "12px",
                                                color: "grey",
                                              }}
                                            >
                                              {this.state.copiedItem ===
                                              field.code
                                                ? _("copied")
                                                : _("copie")}
                                            </span>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="cell small-10">
                                      <span style={{ fontSize: "13px" }}>
                                        {(field["title"][lng]
                                          ? field["title"][lng]
                                          : field["title"][ucFirst(lng)]) +
                                          ` : ${field["code"]}`}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ),
                        );
                      })}
                  </div>
                )}
                {!isUaNewsletter && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                      marginRight: "300px",
                    }}
                  >
                    <span style={{ margin: "6px" }}>{_("model_object")}</span>
                    <SubjectBox
                      onFocus={() => this.setState({ activeInput: "subject" })}
                      onRef={(ref) => (this.child = ref)}
                      classname={"text_area"}
                      isDragAndDropEditor={true}
                    />
                  </div>
                )}

                <EmailEditor
                  id="drag-n-drop-editor"
                  ref={(editor) => (this.editor = editor)}
                  projectId={Number.parseInt(UNLAYER_PROJECT_ID)}
                  onLoad={this.onLoad}
                  minHeight={this.minHeight}
                  options={options}
                  tools={tools}
                  locale={lng === "en" ? "en-US" : "fr-FR"}
                />
              </>
            ))}
          {editorLoaded && !isUaNewsletter && (
            <div className="send-btn">
              <button className={`btn send`} onClick={this.toggleTestForm}>
                <img src="/img/icons/EMAIL-light.svg" />
                {_("send a test")}
              </button>
            </div>
          )}
        </div>
        {showImageGallery && (
          <ImageSelector
            {...this.props}
            done={done}
            onCloseModal={this.toggleImageGallery}
          />
        )}
        {showSaveForm && !isUaNewsletter && (
          <SaveThemeForm
            {...this.props}
            {...this.state}
            onCloseModal={this.toggleSaveForm}
          />
        )}
        {showTestForm && !isUaNewsletter && (
          <SendTestForm
            processing={updating}
            onCloseModal={this.toggleTestForm}
            onSave={this.sendTest}
          />
        )}
      </Fragment>
    );
  }

  onLoad = () => {
    let { design } = this.state;
    let editor = this.editor || unlayer;

    this.loadDesign(design);

    editor.addEventListener("design:updated", () => {
      this.setState({ isDesignUpdated: true });
      this.props.dispatch(setThemeIsSaved(false));
      editor.exportHtml(({ html, design }) => {
        if ( this.props.templates &&  this.props.templates.length > 0) {
          let theme = {
            ...this.props.currentTheme,
            id: this.props.templates[0].id,
            design,
            content: html,
          };
          this.props.dispatch(setCurrentDndTheme(theme));
          this.props.dispatch(setCurrentCampaignTheme(theme));
         
        } else {
          let theme = { ...this.props.currentTheme, design, content: html };
          dispatch(setCurrentDndTheme(theme));
          dispatch(setCurrentCampaignTheme(theme));
         
        }
      });
      this.props.triggerRender && this.props.triggerRender();
    });

    editor.registerCallback("selectImage", (data, done) => {
      this.setState({ showImageGallery: true, done });
    });

    editor.addEventListener("design:loaded", (data) => {
      let hasError = !data?.design?.rows;
      this.setState({ editorLoaded: !hasError, editorHasError: hasError });
    });
  };
}

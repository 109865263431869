import { S3_FOLDER_URL} from "Config";
import _ from "i18n";
import {TEMPLATE_TYPES } from "../constants";

export const isEmpty = (value) => {
    if (Array.isArray(value) || typeof value === 'string') {
      return value.length === 0;
    }
    if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length === 0;
    }
    return !value;
  };
export function isUrl(str) {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  
    return regexp.test(str);
  }
  
  export const trimUrlSlashes = (url) => url.replace(/^\/+|\/+$/g, '');
  
  export const prepareS3ResourceUrl = (
    baseS3Url,
    urlPath,
    appendEventsFolder = true,
  ) => {
    if (isUrl(urlPath ?? '')) return urlPath;
    if (isEmpty(baseS3Url) || isEmpty(urlPath) || !baseS3Url || !urlPath) return '';
  
    const base = trimUrlSlashes(baseS3Url);
    const path = trimUrlSlashes(urlPath).replace('eventsFolder', 'events-folder');
    const pathPrefix =
      appendEventsFolder && path.split('/')[0] !== 'events-folder' ? 'events-folder' : '';
  
    return `${base}${isEmpty(pathPrefix) ? '/' : `/${pathPrefix}/`}${path}`;
  };

export const formatFormation = (event) => {
    let {
        id,
        nameFr,
        isVirtual,
        eventDate,
        startDateTime,
        endDateTime,
        memberPrice,
        nonMemberPrice,
        clientData,
        languages,
    } = event;
    const bannerImgUrl = prepareS3ResourceUrl(S3_FOLDER_URL,event.urlBannerFr);

    let mode = isVirtual ? "En live" : "Présentiel";
    let location = isVirtual ? "En live" : "Sur place";

    let speakerEventName = "";
    let speakerPhoto = "";

    let speakers = event["speakers-abstract"]?.speakers || []; 

    if (speakers.length > 0) {
        let firstSpeaker = speakers[0];
        speakerEventName = `${firstSpeaker.firstName || ""} ${firstSpeaker.lastName || ""}`.trim();
      
        speakerPhoto = firstSpeaker.pictureUrl ? firstSpeaker.pictureUrl : "";
        
    }

   
    let eventUrl = event.urlBadgeFr && event.urlBadgeFr.startsWith("http") ? event.urlBadgeFr : "";

    let fiduciaireLogo = clientData?.avatarUrl || "";
    let formattedEventDate = eventDate 
        ? new Date(eventDate).toLocaleDateString("fr-FR", { day: "2-digit", month: "short", year: "numeric" }) 
        : "";
    let formattedStartTime = startDateTime ? new Date(startDateTime).toISOString().split("T")[1].slice(0, 5) : "";
    let formattedEndTime = endDateTime ? new Date(endDateTime).toISOString().split("T")[1].slice(0, 5) : "";
    const parsedLanguages = JSON.parse(languages); 
    const languageKey = Object.keys(parsedLanguages)[0].toLowerCase();
    
    return {
        id,
        type: TEMPLATE_TYPES["FORMATION_LAYOUT"],
        nameFr: nameFr || "",
        mode,
        speakerEventName,
        speakerPhoto,
        location,
        eventDate: formattedEventDate,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        priceEvent: nonMemberPrice !== undefined && nonMemberPrice !== null ? `${nonMemberPrice}` : "0",
        memberPrice: memberPrice !== undefined && memberPrice !== null ? `${memberPrice}` : "0",    
        url: eventUrl,
        fiduciaireLogo,
        eventImage:bannerImgUrl,
        urlEvent:`https://offfcourse.be/${languageKey}/event/${id}/reception`,


    };
};

import moment from "moment";

const FAKE_FEED_COVER =
  "https://s3.tamtam.pro/prod/emailing/media/4/b5640f1490385fd0296db9b35ff742fbba1eff6a.png";
const FAKE_FEED_AVATAR =
  "https://s3.tamtam.pro/prod/emailing/media/4/3cafab66c19cda59f259e1e231e8d83e070bf31a.png";

export const FAKE_FEEDS = {
  NEWS_LAYOUT: {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    articleUrl:
      "https://lorem.ipsum.be/myminfin-web/pages/fisconet?_ga=2.119431564.442213794.1598470523-772321525.1505718018#!/document/9c789104-a017-4549-af32-1394a25ace9c",
    introduction:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellendus natus, sint ea optio dignissimos asperiores inventore a molestiae dolorum placeat repellat excepturi mollitia ducimu",
    cover: FAKE_FEED_COVER,
    tags: "#adipisicing #ipsum #asperiores",
    authorName: "John Doe",
    authorAvatar: FAKE_FEED_AVATAR,
    hideAvatar: "inline-block",
    ReadMore: "Lire la suite",
    publishedAt: "21 avril 2019",
    articlePage: "LOREM IPSUM",
    category: "Fiscalité",
    color: "#e2101a",
    authorHeadline: "Aliquam erat volutpat",
  },
  SPEAKER_LAYOUT: {
    name: "John Doe",
    avatar: FAKE_FEED_AVATAR,
    headline: "Lorem ipsum dolor sit",
  },
  SLOT_LAYOUT: {
    name: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    language: "fr",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellendus natus, sint ea optio dignissimos asperiores inventore a molestiae dolorum placeat repellat excepturi mollitia ducimu",
    picture: FAKE_FEED_COVER,
    speakers: `-- John Doe`,
    startDate: "Samedi 21 avril / 09:00 - 09:30",
    url: "",
  },
  FORMATION_LAYOUT: {
    // title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    startDate: "Live :  21 mrt. 2023 van 12:30 tot 13:30",
    name: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellendus natus, sint ea optio dignissimos asperiores inventore a molestiae dolorum placeat repellat excepturi mollitia ducimu",
    cover: FAKE_FEED_COVER,
    price: "69€",
    duration: "60 min",
    formDesc: "Bevat de optie: Herbekijken",
    speakerName: `-- John Doe`,
    speakerSecondName: ``,
    speakerAvatar: FAKE_FEED_AVATAR,
    speakerSecondAvatar: "",
    speakerHeadline: "Lorem ipsum dolor sit",
    speakerSecondHeadline: "",
    formationUrl:
      "https://lorem.ipsum.be/myminfin-web/pages/fisconet?_ga=2.119431564.442213794.1598470523-772321525.1505718018#!/document/9c789104-a017-4549-af32-1394a25ace9c",
    nameFr:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellendus natus, sint ea optio dignissimos asperiores inventore a molestiae dolorum placeat repellat excepturi mollitia ducimu",
    nameNl:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellendus natus, sint ea optio dignissimos asperiores inventore a molestiae dolorum placeat repellat excepturi mollitia ducimu",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellendus natus, sint ea optio dignissimos asperiores inventore a molestiae dolorum placeat repellat excepturi mollitia ducimu",
  },
};

const NEWS_LAYOUT_FIELDS = [
  { key: "ARTICLE_TITLE", property: "title", main: 1 },
  { key: "ARTICLE_INTRODUCTION", property: "introduction" },
  { key: "ARTICLE_AUTHOR_NAME", property: "authorName" },
  { key: "ARTICLE_AUTHOR_AVATAR", property: "authorAvatar" },
  { key: "ARTICLE_COVER", property: "cover" },
  { key: "ARTICLE_URL", property: "articleUrl" },
  { key: "ARTICLE_TAGS", property: "tags" },
  { key: "ARTICLE_PAGE", property: "articlePage" },
  { key: "DISPLAY_PAGE_TITLE", property: "displayPageTitle" },
  { key: "HIDE_AVATAR", property: "hideAvatar" },
  { key: "ARTICLE_AUTHOR_HEADLINE", property: "authorHeadline" },
  { key: "DISPLAY_HEADLINE", property: "displayHeadline" },
  { key: "ARTICLE_QUOTATION", property: "quotation" },
  { key: "ARTICLE_CATEGORY", property: "category" },
  { key: "ARTICLE_COLOR", property: "color" },
  { key: "PUBLISHED_AT", property: "publishedAt" },
  { key: "AUTHOR_FUNCTION", property: "authorProfession" },
  { key: "ORGANIZATION_NAME", property: "organisationName" },
  { key: "ARTICLE_TYPE", property: "articleType" },
  { key: "TYPE_COLOR", property: "articleTypeColor" },
  { key: "READ_TIME", property: "readTime" },
];

const SPEAKER_LAYOUT_FIELDS = [
  { key: "SPEAKER_NAME", property: "name", main: 1 },
  { key: "SPEAKER_AVATAR", property: "avatar" },
  { key: "SPEAKER_HEADLINE", property: "headline" },
];

const SLOT_LAYOUT_FIELDS = [
  { key: "SLOT_NAME", property: "name", main: 1 },
  { key: "SLOT_DESCRIPTION", property: "description" },
  { key: "SLOT_PICTURE", property: "picture" },
  { key: "SLOT_SPEAKERS", property: "speakers" },
  { key: "SLOT_START_DATE", property: "startDate" },
  { key: "SLOT_END_DATE", property: "endDate" },
  { key: "SLOT_URL", property: "url" },
];
const FORMATION_LAYOUT_FIELDS = [
  { key: "FORMATION_TITLE", property: "title", main: 1 },
  { key: "FORMATION_START_DATE", property: "startDate" },
  { key: "FORMATION_NAME", property: "name" },
  { key: "FORMATION_COVER", property: "cover" },
  { key: "FORMATION_PRICE", property: "price" },
  { key: "FORMATION_DURATION", property: "duration" },
  { key: "FORMATION_FORM_DESC", property: "formDesc" },
  { key: "FORMATION_SPEAKER", property: "speakerName" },
  { key: "FORMATION_SECOND_SPEAKER", property: "speakerSecondName" },
  { key: "FORMATION_AVATAR", property: "speakerAvatar" },
  { key: "FORMATION_SECOND_AVATAR", property: "speakerSecondAvatar" },
  { key: "FORMATION_HEADLINE", property: "speakerHeadline" },
  { key: "FORMATION_SECOND_HEADLINE", property: "speakerSecondHeadline" },
  { key: "FORMATION_URL", property: "formationUrl" },
  { key: "FORMATION_NAME_FR", property: "nameFr" },
  { key: "FORMATION_NAME_NL", property: "nameNl" },
  { key: "FORMATION_NAME_EN", property: "nameEn" },
  { key: "EVENT_TITLE", property: "nameFr" },           
  { key: "EVENT_MODE", property: "mode" },            
  { key: "EVENT_SPEAKE_NAME", property: "speakerEventName" },
  { key: "EVENT_SPEAKE_PHOTO", property: "speakerPhoto" },
  { key: "EVENT_LOCATION", property: "location" },
  { key: "EVENT_DATE", property: "eventDate" },
  { key: "EVENT_TIME_START", property: "startTime" },
  { key: "EVENT_TIME_END", property: "endTime" },  
  { key: "EVENT_PRICE", property: "priceEvent" },            
  { key: "MEMBER_PRICE", property: "memberPrice" },     
  { key: "EVENT_URL", property: "urlEvent" },
  { key: "FIDUCIAIRE_LOGO", property: "fiduciaireLogo" },
  { key: "EVENT_IMAGE", property: "eventImage" },
];

export const LAYOUT_STRING_CODES = {
  NEWS_LAYOUT: [...NEWS_LAYOUT_FIELDS],
  SPEAKER_LAYOUT: [...SPEAKER_LAYOUT_FIELDS],
  SLOT_LAYOUT: [...SLOT_LAYOUT_FIELDS],
  FORMATION_LAYOUT: [...FORMATION_LAYOUT_FIELDS],
  ALL: [{ key: "READ_MORE_LABEL", property: "ReadMore" }],
};

export const ALL_LAYOUT_STRING_CODES = [
  [...NEWS_LAYOUT_FIELDS],
  [...SPEAKER_LAYOUT_FIELDS],
  [...SLOT_LAYOUT_FIELDS],
  [...FORMATION_LAYOUT_FIELDS],
  { key: "READ_MORE_LABEL", property: "ReadMore" },
].flat();

export const UNREFERENCED_BLOCK_CODES = {
  NL_DATE_MY: () => moment().format("MMMM YYYY"),
  NL_DATE_DMY: () => moment().format("DD MMMM YYYY"),
  FIDUCIARY_LOGO: "fiduciaryLogo",
  NL_NEWSLETTER: "nlNewsletter",
  INTRODUCTION_NL: "introduction",
};

export const escapeJsonSpecialChars = (str) => {
  return str
    .replace(/[\\]/g, "\\\\")
    .replace(/[\"]/g, '\\"')
    .replace(/[\/]/g, "\\/")
    .replace(/[\b]/g, "\\b")
    .replace(/[\f]/g, "\\f")
    .replace(/[\n]/g, "\\n")
    .replace(/[\r]/g, "\\r")
    .replace(/[\t]/g, "\\t");
};

export const EMPTY_COLUMN_EXAMPLE = {
  contents: [],
  values: {
    _meta: {
      htmlID: "u_column_1",
      htmlClassNames: "u_column",
    },
  },
};

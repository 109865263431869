import React, { Component } from "react";
import { connect } from "react-redux";

import { initCampaignRecipientsFilter } from "actions";

import _ from "i18n";

import { ucFirst } from "../../../services/common";
import { MEMBER_UA_RECIPIENT_TYPES, SVG_ICONS } from "../../../config/Common";
import TTPSelect from "common/TTPSelect";
import EmailTagsInput from "../../common/campaign/EmailTagsInput";
import Recipients from "./recipients/Recipients";
import { setCurrentEmailCampaignData } from "../../../actions/actions/currentCampaign";
import StepsFooter from "./StepsFooter";
import {
  fetchUaRecipients,
  fetchTotalRecipients,
} from "../../../actions/thunks/campaign";
import GroupsFilters from "./recipients/filter/GroupsFilters";
import {
  setIsUaVersion,
  setRecipientsPageSize,
  setUaRecipientsTotalCount,
} from "../../../actions/actions/campaign";
import classnames from "classnames";
import { WarningBar } from "./notifications/WarningBar";
import { TTPModal } from "../../common/Modal/TTPModal";
import { NotificationManager } from "react-notifications";
import ClientFilters from "./recipients/filter/ClientFilters";
import { SVG_ALERT_CERCLE_ICON } from "../../../services/svgIcones";

@connect((store) => {
  return {
    recipients: store.campaigns.recipients,
    _requests: store.params.sourcesTokens,
    currentStep: store.currentCampaign.email.currentSideBar === "RECIPIENTS",
    language: store.currentCampaign.email.language,
    recipientType: store.currentCampaign.email.recipientType,
    allowedGroups: store.currentCampaign.email.allowedGroups,
    deniedGroups: store.currentCampaign.email.deniedGroups,
    surveyType: store.currentCampaign.email.surveyType,
    surveyInstance: store.currentCampaign.email.surveyInstance,
    commitmentScoreRange: store.currentCampaign.email.commitmentScoreRange,
    recipientsApp: store.currentCampaign.email.recipientsApp,
    manualRecipients: store.currentCampaign.email.manualRecipients,
    recipientsCount: store.campaigns.recipients.recipientsTotalCount,
    manualSmsRecipients: store.currentCampaign.sms.manualSmsRecipients,
    uaRecipientType: store.currentCampaign.email.uaRecipientType,
    uaRecipientsCount: store.campaigns.recipients.uaRecipientsTotalCount,
    recipientCriteria: store.campaigns.list.recipientCriteria,
    paginationPage: store.campaigns.recipients.filter.paginationPage,
    uaFilters: store.currentCampaign.email.uaFilters,
    auth: store.auth,
    liteRecipients: store.currentCampaign.email.liteRecipients,
    personalizedRecipients: store.currentCampaign.email.personalizedRecipients,
    eliminatedRecipients: store.currentCampaign.email.eliminatedRecipients,
    nbOfRecipients: store.currentCampaign.email.nbOfRecipients,
    totalUaRecipients: store.currentCampaign.email.totalUaRecipients,
    titleSelection: store.currentCampaign.email.titleSelection,
    totalUniqueRecipients: store.campaigns.recipients.totalUniqueRecipients,
  };
})
export default class RecipientsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uaRecipientsTotalCount: [],
      //selectedRecipientCriteria: null,
      plan: "",
      allSelected: false,
      recipientsItems: [],
      count: 0,
      selectedPage: false,
      selectAllRecipient: false,
      isOpenModal: false,
      typesFilter: "CONTACT",
      //isRecipientCriteriaLoading: false,
      isFiltersChanged: false,
      selectedTab: null,
    };
  }

  componentDidMount() {
    let { dispatch, auth, newsletterId } = this.props;
    dispatch(initCampaignRecipientsFilter());
    dispatch(setIsUaVersion(true));
    dispatch(setRecipientsPageSize(20));
    const nlpreferences = auth.currentClient.newsletterPreferences;
    if (nlpreferences !== null) {
      if (nlpreferences?.apps?.length > 0) {
        var filteredApps = nlpreferences.apps.filter(
          (app) => app.type === "BLOG_NL_CREATE",
        );
        this.setState({
          plan: filteredApps[0].config,
        });
      }
    }
    if (newsletterId !== null) {
     this.updateSelectedTab(null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      dispatch,
      language,
      recipientsCount,
      recipientType,
      manualRecipients,
      paginationPage,
      uaFilters,
      nbOfRecipients,
      recipientsTab,
    } = this.props;
    let { filter } = this.props.recipients;
    let { uaRecipientsTotalCount } = this.state;

    const hasItems = recipientsTab.some(
      (recipient) =>
        recipient.recipientType === recipientType && recipient.items,
    );
    let updatedRecipientsTab = [...recipientsTab];

    const uaFilterForRecipientType = uaFilters.find(
      (filter) => filter.type === recipientType,
    );
    const recipientObject = updatedRecipientsTab.find(
      (recipient) => recipient.recipientType === recipientType,
    );

    const prevPropsUaFilterForRecipientType = prevProps.uaFilters.find(
      (filter) => filter.type === recipientType,
    );

    const prevAllowedGroups =
      prevPropsUaFilterForRecipientType &&
      uaFilterForRecipientType?.allowedGroups
        ? uaFilterForRecipientType.allowedGroups.map((group) => group.id)
        : [];

    const prevDeniedGroups =
      prevPropsUaFilterForRecipientType &&
      uaFilterForRecipientType?.deniedGroups
        ? uaFilterForRecipientType.deniedGroups.map((group) => group.id)
        : [];

    const currentAllowedGroups = uaFilterForRecipientType?.allowedGroups
      ? uaFilterForRecipientType.allowedGroups.map((group) => group.id)
      : [];
    const currentDeniedGroups = uaFilterForRecipientType?.deniedGroups
      ? uaFilterForRecipientType.deniedGroups.map((group) => group.id)
      : [];
    const currentCupboard = uaFilterForRecipientType
      ? uaFilterForRecipientType.cupboard
      : null;

    const currentExternalType = uaFilterForRecipientType
      ? uaFilterForRecipientType.externalType
      : null;

    const currentProfession = uaFilterForRecipientType
      ? uaFilterForRecipientType.profession
      : null;
    const recipientAllowedGroups = recipientObject?.allowedGroups || [];
    const recipientDeniedGroups = recipientObject?.deniedGroups || [];

    if (uaFilterForRecipientType && recipientsTab?.length > 0) {
      const { allowedGroups: uaAllowedGroups, deniedGroups: uaDeniedGroups } =
        uaFilterForRecipientType;

      const extractedAllowedGroupIds = uaAllowedGroups.flatMap(
        (group) => group.id,
      );
      const extractedDeniedGroupIds = uaDeniedGroups.flatMap(
        (group) => group.id,
      );

      if (
        JSON.stringify(extractedAllowedGroupIds) !==
          JSON.stringify(prevAllowedGroups) ||
        JSON.stringify(extractedDeniedGroupIds) !==
          JSON.stringify(prevDeniedGroups) ||
        JSON.stringify(extractedAllowedGroupIds) !==
          JSON.stringify(recipientAllowedGroups) ||
        JSON.stringify(extractedDeniedGroupIds) !==
          JSON.stringify(recipientDeniedGroups)
      ) {
        updatedRecipientsTab = recipientsTab.map((recipient) =>
          recipient.recipientType === recipientType
            ? {
                ...recipient,
                allowedGroups: extractedAllowedGroupIds,
                deniedGroups: extractedDeniedGroupIds,
              }
            : recipient,
        );
        this.props.handleSetRecipientTab(updatedRecipientsTab);
        dispatch(
          setCurrentEmailCampaignData({
            allowedGroups: extractedAllowedGroupIds,
            deniedGroups: extractedDeniedGroupIds,
          }),
        );
      }
    }

    if (
      filter.name !== prevProps.recipients.filter.name ||
      language !== prevProps.language ||
      (recipientType !== prevProps.recipientType && !hasItems) ||
      (paginationPage !== prevProps.paginationPage &&
        recipientObject?.paginationPage !== paginationPage) ||
      (JSON.stringify(updatedRecipientsTab) !==
        JSON.stringify(prevProps.recipientsTab) &&
        (JSON.stringify(prevAllowedGroups) !==
          JSON.stringify(currentAllowedGroups) ||
          JSON.stringify(prevDeniedGroups) !==
            JSON.stringify(currentDeniedGroups) ||
          (JSON.stringify(prevAllowedGroups) ===
            JSON.stringify(currentAllowedGroups) &&
            JSON.stringify(recipientAllowedGroups) !==
              JSON.stringify(currentAllowedGroups)) ||
          (JSON.stringify(prevDeniedGroups) ===
            JSON.stringify(currentDeniedGroups) &&
            JSON.stringify(recipientDeniedGroups) !==
              JSON.stringify(currentDeniedGroups)))) ||
      this.state.isFiltersChanged !== prevState.isFiltersChanged
    ) {
      let combinedProps = {
        ...this.props,
        selectedTab: this.state.selectedTab,
      };
      this.renderRecipientDatatable(
        combinedProps,
        currentAllowedGroups,
        currentDeniedGroups,
        currentCupboard,
        currentExternalType,
        currentProfession,
      );
    }

    if (
      (recipientsCount !== prevProps.recipientsCount &&
        !isNaN(recipientsCount)) ||
      manualRecipients !== prevProps.manualRecipients
    ) {
      const existingType = uaRecipientsTotalCount.findIndex(
        (obj) => obj.property === recipientType,
      );
      if (existingType !== -1) {
        const updatedArray = [...uaRecipientsTotalCount];
        updatedArray[existingType].nbRecipients =
          recipientType === "MANUAL"
            ? manualRecipients.length
            : recipientsCount;
        this.setState({ uaRecipientsTotalCount: updatedArray });
      } else {
        if (
          !this.state.uaRecipientsTotalCount.some(
            (obj) => obj.property === recipientType,
          )
        ) {
          this.setState((prevState) => ({
            uaRecipientsTotalCount: [
              ...uaRecipientsTotalCount,
              {
                property: recipientType,
                nbRecipients:
                  recipientType === "MANUAL"
                    ? manualRecipients.length
                    : recipientsCount,
              },
            ],
          }));
        }
      }
    }

    if (uaRecipientsTotalCount !== prevState.uaRecipientsTotalCount) {
      const totalRecipients = uaRecipientsTotalCount.reduce(
        (sum, obj) => sum + obj.nbRecipients,
        0,
      );
      dispatch(setUaRecipientsTotalCount(totalRecipients));
    }
    if (manualRecipients !== prevProps.manualRecipients) {
      const addedManualRecipients = manualRecipients?.length || 0;
      const prevManualRecipients = prevProps.manualRecipients?.length || 0;

      const changeInRecipients = addedManualRecipients - prevManualRecipients;
      const updatedNbOfRecipients = nbOfRecipients + changeInRecipients;
      updatedNbOfRecipients >= 0 &&
        dispatch(
          setCurrentEmailCampaignData({
            nbOfRecipients: updatedNbOfRecipients,
          }),
        );
    }
    if (recipientsTab !== prevProps.recipientsTab) {
      this.updateTotalUaRecipients();
    }
  }

  renderRecipientDatatable(
    filter,
    currentAllowedGroups,
    currentDeniedGroups,
    currentCupboard,
    currentExternalType,
    currentProfession,
  ) {
    let {
      deniedGroups,
      allowedGroups,
      language,
      surveyType,
      surveyInstance,
      uaRecipientType,
      recipientType,
      selectedTab,
      uaFilters,
    } = filter;
    const { dispatch } = this.props;

    if (["", "MANUAL"].indexOf(uaRecipientType) !== -1) {
      return;
    }

    let filterData = {
      groupsIn: currentAllowedGroups ? currentAllowedGroups : allowedGroups,
      groupsNin: currentDeniedGroups ? currentDeniedGroups : deniedGroups,
      language,
      recipientType,
      surveyType,
      surveyInstance,
      uaRecipientType,
      uaFilters,
    };
    if (currentCupboard) {
      filterData.cupboard = currentCupboard;
    }
    if (currentExternalType) {
      filterData.externalType = currentExternalType;
    }
    if (currentProfession) {
      filterData.profession = currentProfession;
    }
    if (selectedTab === "BOUNCED") {
      filterData.bounced = true;
    }

    dispatch(fetchUaRecipients(filterData));
    dispatch(fetchTotalRecipients(filterData));
  }

  getOptions = (fields) => {
    return fields.map((type) => {
      return { id: type + "", label: ucFirst(_(type)).replace(/_/g, " ") };
    });
  };
  handleSetIsFilterChanged = () => {
    this.setState({ isFiltersChanged: !this.state.isFiltersChanged });
  };
  getRecipientCriteriaOptions = (fields) => {
    return fields.map((element) => {
      return { id: element.id, label: ucFirst(_(element.title)) };
    });
  };

  handleRecipientTypeChange = (value) => {
    this.props.dispatch(
      setCurrentEmailCampaignData({
        recipientType: value,
        uaRecipientType: value,
        allowedGroups: [],
        deniedGroups: [],
      }),
    );
  };

  onManualRecipientsChange = (manualRecipients) => {
    this.props.dispatch(setCurrentEmailCampaignData({ manualRecipients }));
  };
  onLiteRecipientsChange = (target, isSelected) => {
    const { liteRecipients, dispatch, recipientType } = this.props;
    const { allSelected } = this.state;

    if (Array.isArray(isSelected) && isSelected.length > 0) {
      const updatedLiteRecipients = liteRecipients.filter((recipient) => {
        return !isSelected.includes(recipient.email);
      });
      dispatch(
        setCurrentEmailCampaignData({
          liteRecipients: updatedLiteRecipients,
        }),
      );
      this.setState({ allSelected: false });
    } else if (isSelected) {
      const updatedRecipients = target.map((recipient) => ({
        email: recipient.mainEmail,
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        recipientType: recipientType,
      }));
      if (allSelected) {
        const updatedRecipientsFiltred = liteRecipients.filter(
          (liteRecipient) => {
            return !updatedRecipients.some(
              (updatedRecipient) =>
                updatedRecipient.email === liteRecipient.email,
            );
          },
        );
        dispatch(
          setCurrentEmailCampaignData({
            liteRecipients: updatedRecipientsFiltred,
          }),
        );
        this.setState({ allSelected: false });
      } else {
        const updatedRecipientsWithoutDuplicates = updatedRecipients.filter(
          (updatedRecipient) =>
            !liteRecipients.some(
              (liteRecipient) => liteRecipient.email === updatedRecipient.email,
            ),
        );
        dispatch(
          setCurrentEmailCampaignData({
            liteRecipients: [
              ...liteRecipients,
              ...updatedRecipientsWithoutDuplicates,
            ],
          }),
        );
        this.setState({ allSelected: true });
      }
    } else {
      const targetEmail = target.mainEmail;
      const isEmailInLiteRecipients = liteRecipients.some(
        (recipient) => recipient.email === targetEmail,
      );

      const updatedRecipients = isEmailInLiteRecipients
        ? liteRecipients.filter((recipient) => recipient.email !== targetEmail)
        : [
            ...liteRecipients,
            {
              email: target.mainEmail,
              firstName: target.firstName,
              lastName: target.lastName,
              recipientType: recipientType,
            },
          ];
      dispatch(
        setCurrentEmailCampaignData({
          liteRecipients: updatedRecipients,
        }),
      );
    }
    this.updateRecipientsTab();
  };

  changePersonalizedRecipients = (target, isSelected) => {
    const {
      dispatch,
      recipientType,
      personalizedRecipients,
      eliminatedRecipients,
    } = this.props;
    const { allSelected, selectedPage, selectAllRecipient } = this.state;
    if (isSelected) {
      this.setState({
        selectedPage: !selectedPage,
        selectAllRecipient: selectedPage,
      });
      //here if we want select one page
      if (!selectedPage) {
        const updatedRecipients = target.map((recipient) => ({
          email: recipient.mainEmail,
          firstName: recipient.firstName,
          lastName: recipient.lastName,
        }));
        const updatedPersonalizedRecipients = {
          ...personalizedRecipients,
          [recipientType]: personalizedRecipients[recipientType]
            ? [...personalizedRecipients[recipientType], ...updatedRecipients]
            : updatedRecipients,
        };
        dispatch(
          setCurrentEmailCampaignData({
            liteRecipients: [],
            personalizedRecipients: updatedPersonalizedRecipients,
            eliminatedRecipients: [],
          }),
        );
      }
    } else {
      const targetEmail = target.mainEmail;
      if (selectAllRecipient) {
        const isEmailInEliminatedRecipients =
          Array.isArray(eliminatedRecipients[recipientType]) &&
          eliminatedRecipients[recipientType].some(
            (recipient) => recipient.email === targetEmail,
          );

        const updatedRecipients = isEmailInEliminatedRecipients
          ? {
              ...eliminatedRecipients,
              [recipientType]: eliminatedRecipients[recipientType].filter(
                (recipient) => recipient.email !== targetEmail,
              ),
            }
          : {
              ...eliminatedRecipients,
              [recipientType]: [
                ...(eliminatedRecipients[recipientType] || []),
                {
                  email: target.mainEmail,
                  firstName: target.firstName,
                  lastName: target.lastName,
                },
              ],
            };

        dispatch(
          setCurrentEmailCampaignData({
            eliminatedRecipients: updatedRecipients,
          }),
        );
      } else {
        const isEmailInPersonalizedRecipients = personalizedRecipients[
          recipientType
        ]?.some((recipient) => recipient.email === targetEmail);

        const updatedRecipients = { ...personalizedRecipients };
        if (isEmailInPersonalizedRecipients) {
          // Remove the recipient with the target email
          if (updatedRecipients[recipientType]) {
            updatedRecipients[recipientType] = updatedRecipients[
              recipientType
            ].filter((recipient) => recipient.email !== targetEmail);
          }
        } else {
          // Add the new recipient
          if (!updatedRecipients[recipientType]) {
            updatedRecipients[recipientType] = [];
          }
          updatedRecipients[recipientType].push({
            email: target.mainEmail,
            firstName: target.firstName,
            lastName: target.lastName,
          });
        }

        dispatch(
          setCurrentEmailCampaignData({
            personalizedRecipients: updatedRecipients,
          }),
        );
      }
    }
  };
  updateRecipientsTab = () => {
    const {
      recipientType,
      personalizedRecipients,
      eliminatedRecipients,
      recipientsTab,
      liteRecipients,
    } = this.props;
    const { selectAllRecipient, selectedPage } = this.state;

    const updatedRecipientsTab = recipientsTab.map((recipient) => {
      const updatedRecipient = {
        ...recipient,
        personalizedRecipients,
        eliminatedRecipients,
        liteRecipients,
      };
      if (recipient.recipientType === recipientType) {
        updatedRecipient.selectAllRecipient = selectAllRecipient;
        updatedRecipient.selectedPage = selectedPage;
      }
      return updatedRecipient;
    });
    this.props.handleSetRecipientTab(updatedRecipientsTab);
  };
  updateSelectAllState = (recipientItems) => {
    const { liteRecipients } = this.props;

    const recipientEmails = (recipientItems || []).map(
      (recipient) => recipient.mainEmail,
    );

    const allSelected = liteRecipients
      ? recipientEmails.every((email) =>
          liteRecipients.some((liteRecipient) => liteRecipient.email === email),
        )
      : false;

    this.setState({ allSelected: allSelected });
  };
  setSelectAll = () => {
    this.setState({
      allSelected: !this.state.allSelected,
    });
  };
  handleFetchRecipient = (value) => {
    const { dispatch } = this.props;
    dispatch(
      setCurrentEmailCampaignData({
        recipientType: value,
        allowedGroups: [],
        deniedGroups: [],
      }),
    );
  };
  handleChangeRecipientType = (value) => {
    const {
      dispatch,
      uaRecipientType,
      liteRecipients,
      recipientsTab,
      personalizedRecipients,
      eliminatedRecipients,
      totalUaRecipients,
    } = this.props;
    const { uaRecipientsTotalCount } = this.state;
    const lastValue = value[value.length - 1];

    this.setState({
      selectedTab: null,
    });
    const removedRecipientTypes = uaRecipientType
      .filter((option) => !value.includes(option))
      .map((option) => option);

    const updatedArray = uaRecipientsTotalCount.filter(
      (obj) => !removedRecipientTypes.includes(obj.property),
    );
    const updatedTotalUaRecipients = totalUaRecipients
      ? Object.keys(totalUaRecipients).reduce((acc, key) => {
          if (!removedRecipientTypes.includes(key)) {
            acc[key] = totalUaRecipients[key];
          }
          return acc;
        }, {})
      : {};

    this.setState({
      uaRecipientsTotalCount: updatedArray,
    });
    if (removedRecipientTypes.length > 0) {
      const filteredRecipientsTab = recipientsTab.filter(
        (item) => item.recipientType !== removedRecipientTypes[0],
      );
      this.props.handleSetRecipientTab(filteredRecipientsTab);

      const updatedLiteRecipients = (liteRecipients ?? []).filter(
        (recipient) => recipient.recipientType !== removedRecipientTypes[0],
      );

      const updatedPersonalizedRecipients = Object.keys(
        personalizedRecipients,
      ).reduce((acc, key) => {
        if (key !== removedRecipientTypes[0]) {
          acc[key] = personalizedRecipients[key];
        }
        return acc;
      }, {});

      const updatedEliminatedRecipients = Object.keys(
        eliminatedRecipients,
      ).reduce((acc, key) => {
        if (key !== removedRecipientTypes[0]) {
          acc[key] = eliminatedRecipients[key];
        }
        return acc;
      }, {});

      dispatch(
        setCurrentEmailCampaignData({
          liteRecipients: updatedLiteRecipients,
          personalizedRecipients: updatedPersonalizedRecipients,
          eliminatedRecipients: updatedEliminatedRecipients,
        }),
      );
    }
    dispatch(
      setCurrentEmailCampaignData({
        uaRecipientType: value,
        recipientType: lastValue,
        allowedGroups: [],
        deniedGroups: [],
        totalUaRecipients: updatedTotalUaRecipients,
      }),
    );
  };

  handleChangeItemsRecipients = (
    itemsResult,
    recipientCounts,
    selectAllRecipient,
    selectedPage,
  ) => {
    this.setState({
      recipientsItems: itemsResult,
      count: itemsResult?.length > 0 ? recipientCounts : 0,
      selectAllRecipient: selectAllRecipient ? selectAllRecipient : false,
      selectedPage: selectedPage ? selectedPage : false,
    });
  };

  handleChangSelectAllRecipient = () => {
    const { selectAllRecipient, selectedPage } = this.state;
    const { personalizedRecipients, recipientType, eliminatedRecipients } =
      this.props;
    this.setState(
      (prevState) => ({
        selectAllRecipient: !prevState.selectAllRecipient,
        selectedPage: !prevState.selectAllRecipient ? false : selectedPage,
      }),
      () => {
        if (!selectAllRecipient) {
          const updatedPersonalizedRecipients = Object.keys(
            personalizedRecipients,
          ).reduce((acc, key) => {
            if (key !== recipientType) {
              acc[key] = personalizedRecipients[key];
            }
            return acc;
          }, {});

          this.props.dispatch(
            setCurrentEmailCampaignData({
              personalizedRecipients: updatedPersonalizedRecipients,
            }),
          );
        } else {
          const updatedEliminatedRecipients = Object.keys(
            eliminatedRecipients,
          ).reduce((acc, key) => {
            if (key !== recipientType) {
              acc[key] = eliminatedRecipients[key];
            }
            return acc;
          }, {});

          this.props.dispatch(
            setCurrentEmailCampaignData({
              eliminatedRecipients: updatedEliminatedRecipients,
            }),
          );
        }
        this.updateRecipientsTab();
      },
    );
  };
  clearSelection = () => {
    this.setState({
      selectedPage: false,
      selectAllRecipient: false,
    });
    this.props.dispatch(
      setCurrentEmailCampaignData({
        personalizedRecipients: [],
      }),
    );
  };

  updateTotalUaRecipients = () => {
    const { recipientsTab, liteRecipients } = this.props;
    const recipientCounts = {};

    recipientsTab.forEach((item) => {
      const personalizedRecipients =
        item.personalizedRecipients &&
        item.personalizedRecipients[item.recipientType];
      const eliminatedRecipients =
        item.eliminatedRecipients &&
        item.eliminatedRecipients[item.recipientType];

      const liteRecipientsFiltred = liteRecipients
        ? liteRecipients.filter(
            (recipient) => recipient.recipientType === item.recipientType,
          )
        : [];

      const count = item.selectAllRecipient
        ? item.count - (eliminatedRecipients ? eliminatedRecipients.length : 0)
        : personalizedRecipients?.length > 0
          ? personalizedRecipients.length
          : 0;

      recipientCounts[item.recipientType] = {
        selectedCount:
          this.state.plan === "LITE" ? liteRecipientsFiltred?.length : count,
        total: item.count,
      };
    });
    this.props.dispatch(
      setCurrentEmailCampaignData({
        totalUaRecipients: recipientCounts,
      }),
    );
  };
  updateSelectedTab = (value) => {
    const {
      deniedGroups,
      allowedGroups,
      language,
      surveyType,
      surveyInstance,
      uaRecipientType,
      recipientType,
    } = this.props;

    this.setState({
      selectedTab: value,
    });

    let filter = {
      deniedGroups,
      allowedGroups,
      language,
      surveyType,
      surveyInstance,
      uaRecipientType,
      recipientType,
      selectedTab: value,
    };

    this.renderRecipientDatatable(filter);
  };

  renderFilters() {
    const { recipientType } = this.props;
    switch (recipientType) {
      case "CONTACT":
        return <GroupsFilters />;
      case "CONTACT_CLIENT":
        return <GroupsFilters />;
      case "CLIENT":
        return (
          <ClientFilters
            handleSetIsFilterChanged={this.handleSetIsFilterChanged}
          />
        );
    }
    return null;
  }

  render() {
    const {
      currentStep,
      recipientType,
      manualRecipients,
      language,
      uaRecipientType,
      uaRecipientsCount,
      recipientCriteria,
      liteRecipients,
      nbOfRecipients,
      totalUaRecipients,
      recipientsTab,
      auth,
      titleSelection,
      uaFilters,
      isRecipientCriteriaLoading,
      selectedRecipientCriteria,
      totalUniqueRecipients,
    } = this.props;
    const {
      plan,
      allSelected,
      recipientsItems,
      count,
      selectedPage,
      selectAllRecipient,
      isOpenModal,
      typesFilter,
      selectedTab,
    } = this.state;

    var isManualRecipientNull =
      uaRecipientType.includes("MANUAL") && manualRecipients.length < 1;

    const liteRecipientEmails = liteRecipients
      ? liteRecipients.map((recipient) => recipient.email)
      : [];

    const isLitePlan = plan === "LITE";
    const isPersonalizedPlan = plan === "PERSONALIZED";
    const isStandardPlan = plan === "STANDARD";

    var isMaxRecipient =
      auth.currentClient?.id === 4 || auth.currentClient?.id === 1256
        ? nbOfRecipients > 10000
        : isLitePlan
          ? nbOfRecipients > 100
          : isPersonalizedPlan
            ? nbOfRecipients > 2500
            : isStandardPlan && nbOfRecipients > 500;

    const typeWithNoRecipients =
      isPersonalizedPlan || isStandardPlan
        ? Object.entries(totalUaRecipients || {}).find(
            ([key, value]) => value.selectedCount === 0,
          )
        : isLitePlan &&
          uaRecipientType
            .filter((type) => type !== "MANUAL")
            .filter(
              (type) =>
                !liteRecipients.some(
                  (recipient) => recipient.recipientType === type,
                ),
            );
    const canSaveSelection = uaRecipientType.length > 1;

    const hasContactFilter = uaFilters.some(
      (filterObject) =>
        filterObject.type === "CONTACT" &&
        (filterObject.deniedGroups.length > 0 ||
          filterObject.allowedGroups.length > 0),
    );
    const hasContactClientFilter = uaFilters.some(
      (filterObject) =>
        filterObject.type === "CONTACT_CLIENT" &&
        (filterObject.deniedGroups.length > 0 ||
          filterObject.allowedGroups.length > 0),
    );
    const totalCount =
      recipientsTab.reduce((sum, recipient) => sum + recipient.count, 0) +
      (manualRecipients ? manualRecipients.length : 0);
    const uniqueTotalRecipients = totalUniqueRecipients
      ? totalUniqueRecipients + (manualRecipients ? manualRecipients.length : 0)
      : 0;

    return (
      <div id="send-tabs__tab" className={`${!currentStep && "hide"}`}>
        <div className="tab">
          <h4 className="tab-header">{ucFirst(_("selectTarget"))}</h4>
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">Choisir une selection</span>
              <div className="small-12">
                <TTPSelect
                  isDisabled={isRecipientCriteriaLoading}
                  simple={true}
                  notClearable={false}
                  placeholder={"Choisir votre selection"}
                  values={selectedRecipientCriteria}
                  options={this.getRecipientCriteriaOptions(recipientCriteria)}
                  cssClass={
                    this.getRecipientCriteriaOptions(recipientCriteria)[0]
                      ? "filled-box"
                      : "empty-box"
                  }
                  onChange={this.props.handleChangeRecipientCriteria}
                />
              </div>
            </div>

            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">{_("recipientType")}</span>
              <div className="small-12">
                <TTPSelect
                  values={uaRecipientType}
                  options={this.getOptions(MEMBER_UA_RECIPIENT_TYPES)}
                  cssClass={uaRecipientType ? "filled-box" : "empty-box"}
                  onChange={this.handleChangeRecipientType}
                />
              </div>
            </div>
          </div>
          <div className="tabs">
            {canSaveSelection && (
              <div className={"save_selection"}>
                <button
                  onClick={() => {
                    if (isManualRecipientNull) {
                      NotificationManager.info(_("manualRecipientNullWarning"));
                    } else if (
                      typeWithNoRecipients &&
                      typeWithNoRecipients.length > 0
                    ) {
                      NotificationManager.info(
                        _("choose_recipients_warning") +
                          ": " +
                          ucFirst(_(typeWithNoRecipients[0]).toLowerCase()),
                      );
                    } else {
                      this.setState({
                        isOpenModal: true,
                      });
                    }
                  }}
                >
                  <img src={"/img/icons/save.svg"} />
                  {_("Sauvegarder la sélection")}
                </button>
              </div>
            )}
          </div>

          <div className="separator"></div>

          <div
            className={classnames(
              "tabs",
              !canSaveSelection ? "tab_centered_content" : "",
            )}
          >
            <div className="columns small-9 category_section">
              {uaRecipientType.map((type) => {
                const recipientObject = recipientsTab.find(
                  (recipient) => recipient.recipientType === type,
                );

                const filteredLiteRecipients = liteRecipients
                  ? liteRecipients.filter(
                      (recipient) => recipient.recipientType === type,
                    )
                  : [];

                // Get the count of recipients with the specified recipientType
                const countLiteRecipients = filteredLiteRecipients.length;

                return (
                  <div className="group_recipients_section">
                    <div
                      onClick={() =>
                        recipientType !== type &&
                        this.handleFetchRecipient(type)
                      }
                      className={classnames(
                        "tab_badge",
                        recipientType === type ? "tab_badge_active" : "",
                      )}
                    >
                      <div> {ucFirst(_(type)).replace(/_/g, " ")}</div>
                    </div>
                    <div
                      className={`group_recipients_count ${recipientType === type ? "group_recipients_count_active" : ""}`}
                    >
                      {type !== "MANUAL" &&
                        (isLitePlan
                          ? countLiteRecipients + "/"
                          : recipientObject &&
                              recipientObject.selectAllRecipient
                            ? recipientObject.count -
                              (recipientObject.eliminatedRecipients?.[type]
                                ?.length || 0) +
                              "/"
                            : recipientObject &&
                                recipientObject.personalizedRecipients &&
                                recipientObject.personalizedRecipients[type] &&
                                recipientObject.personalizedRecipients[type]
                                  ?.length
                              ? recipientObject.personalizedRecipients[type]
                                  ?.length + "/"
                              : 0 + "/")}
                      {/*  : 0 + " /")}*/}
                      {type === "MANUAL"
                        ? manualRecipients
                          ? manualRecipients.length
                          : 0
                        : recipientObject
                          ? recipientObject.count
                          : 0}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="columns small-1 separator_container">
              <div className="v-separator"></div>
            </div>
            <div className={"columns small-2 total_section"}>
              <div className={"total_label"}>{_("total")}</div>
              <div className={"total_number"}>
                <span>
                  {" "}
                  {nbOfRecipients}/{totalCount}{" "}
                  {uniqueTotalRecipients > 0 && (
                    <>
                      ({uniqueTotalRecipients}
                      <span className="tooltip-icon">
                        {SVG_ALERT_CERCLE_ICON}
                        <span className="tooltip-text">
                          {_("duplicate_users_alert")}
                        </span>
                      </span>{" "}
                      ){" "}
                    </>
                  )}
                </span>
              </div>
            </div>
            {/* {canSaveSelection && (
              <div className={"save_selection"}>
                <button
                  onClick={() => {
                    if (isManualRecipientNull) {
                      NotificationManager.info(_("manualRecipientNullWarning"));
                    } else if (
                      typeWithNoRecipients &&
                      typeWithNoRecipients.length > 0
                    ) {
                      NotificationManager.info(
                        _("choose_recipients_warning") +
                          ": " +
                          ucFirst(_(typeWithNoRecipients[0]).toLowerCase()),
                      );
                    } else {
                      this.setState({
                        isOpenModal: true,
                      });
                    }
                  }}
                >
                  <img src={"/img/icons/save.svg"} />
                  {_("Enregistrer la selection")}
                </button>
              </div>
            )} */}
          </div>
          {recipientType === "MANUAL" ? (
            <div className="row">
              <div className="separator"></div>
              <div className="columns small-12 columns-padding">
                <div className="counter">
                  <span className="tab-label">
                    {_("sendTo")} <span className="required">*</span>
                  </span>
                  <span className="tab-label">
                    {/*  {_("Nombre de déstinataires")} :<span className="required">
                      {manualRecipients ? manualRecipients.length : 0}
                    </span>*/}
                  </span>
                </div>
                <EmailTagsInput
                  cssClass="p-xs"
                  emails={manualRecipients}
                  changed={this.onManualRecipientsChange}
                />
              </div>
            </div>
          ) : (
            <div>
              {this.renderFilters()}
              {isLitePlan && recipientType !== "MANUAL" && (
                <div className="row">
                  <div className="columns small-12 columns-padding">
                    <div className="counter">
                      <span className="tab-label">
                        {_("sendTo")} <span className="required">*</span>
                      </span>
                      <span className="tab-label">
                        {_("Nombre de déstinataires")} :
                        <span
                          className={`required ${isMaxRecipient && "alert"}`}
                        >
                          {nbOfRecipients}
                        </span>
                        <span className="required">
                          /
                          {isLitePlan
                            ? 100
                            : isStandardPlan
                              ? 500
                              : isPersonalizedPlan && 2500}
                        </span>
                      </span>
                    </div>

                    <EmailTagsInput
                      cssClass="p-xs"
                      emails={liteRecipientEmails}
                      changed={this.onLiteRecipientsChange}
                      isLite={true}
                    />
                  </div>
                </div>
              )}
              {isMaxRecipient && (
                <WarningBar
                  message={_("max_recipients_alert")}
                  description={_("max_recipients_alert_desc")}
                  plan={_(plan)}
                />
              )}
              <Recipients
                language={language}
                recipientType={recipientType}
                recipientsTab={recipientsTab}
                handleChangeRecipientTab={this.props.handleSetRecipientTab}
                changeLiteRecipients={this.onLiteRecipientsChange}
                setSelectAll={this.setSelectAll}
                selectAll={allSelected}
                updateSelectAllState={this.updateSelectAllState}
                recipientsItems={recipientsItems}
                count={count}
                handleChangeItemsRecipients={this.handleChangeItemsRecipients}
                selectedPage={selectedPage}
                plan={plan}
                changePersonalizedRecipients={this.changePersonalizedRecipients}
                selectAllRecipient={selectAllRecipient}
                handleChangSelectAllRecipient={
                  this.handleChangSelectAllRecipient
                }
                updateRecipientsTab={this.updateRecipientsTab}
                clearSelection={this.clearSelection}
                selectedTab={selectedTab}
                updateSelectedTab={this.updateSelectedTab}
                handleSetIsFilterChanged={this.handleSetIsFilterChanged}
              />
            </div>
          )}
        </div>
        <StepsFooter
          disabled={nbOfRecipients < 1}
          isManualRecipientNull={isManualRecipientNull}
          isMaxRecipient={isMaxRecipient}
          typeWithNoRecipients={
            typeWithNoRecipients ? typeWithNoRecipients[0] : null
          }
          currentPage={this.props.currentPage}
        />
        <TTPModal
          isOpen={isOpenModal}
          onCancel={() => {
            this.setState({ isOpenModal: false });
          }}
          onConfirm={() => {
            this.props.saveRecipientCriteria();
            this.setState({
              isOpenModal: false,
            });
          }}
          inProcess={false}
          actionFailed={false}
          title="Enregistrer la sélection"
          text="Voulez-vous vraiment enregistrer cette sélection ?"
          labelNo="Annuler"
          labelYes="Enregistrer"
          labelError="Error"
        >
          <div>
            <div className="selection-title">
              <span>Titre de selection : </span>
              <input
                type="text"
                value={titleSelection}
                onChange={this.props.handleTitleChange}
                placeholder="Donnez un titre a votre sélection ..."
              />
            </div>

            <div className="selection-info">
              <span>Type(s) de destinataires sélectionnées : </span>
              <div className="tag-types">
                {uaRecipientType.map((recipient, index) => (
                  <span key={index} className="tag">
                    {ucFirst(_(recipient)).replace(/_/g, " ")}
                  </span>
                ))}
              </div>
            </div>
            {uaFilters.length > 0 &&
              ((uaRecipientType.includes("CONTACT") && hasContactFilter) ||
                (uaRecipientType.includes("CONTACT_CLIENT") &&
                  hasContactClientFilter)) && (
                <div className="row">
                  <div className="details">
                    <span className="title">Details</span>
                    <div className="tabs-types">
                      {uaRecipientType.includes("CONTACT") &&
                        hasContactFilter && (
                          <div
                            className={
                              "tab_badge" +
                              " " +
                              (typesFilter === "CONTACT"
                                ? "tab_badge_active"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                typesFilter: "CONTACT",
                              })
                            }
                          >
                            <span>Contact</span>
                          </div>
                        )}
                      {uaRecipientType.includes("CONTACT_CLIENT") &&
                        hasContactClientFilter && (
                          <div
                            className={
                              "tab_badge" +
                              " " +
                              (typesFilter === "CONTACT_CLIENT"
                                ? "tab_badge_active"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                typesFilter: "CONTACT_CLIENT",
                              })
                            }
                          >
                            <span>Contact client</span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="groups">
                    {uaFilters.map((recipient, index) => (
                      <>
                        {recipient.type === typesFilter &&
                          (recipient.allowedGroups.length > 0 ? (
                            <div className="tab-groups">
                              <span>Groupes inclus : </span>
                              <div className="tag">
                                {recipient.allowedGroups.map((group, index) => (
                                  <span key={index} className="allowed-groups">
                                    {group.name}
                                  </span>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className="tab-groups">
                              <span>Groupes exclus : </span>
                              <div className="not-found-msg">
                                <span>Aucun groupe n'est sélectionné</span>
                              </div>
                            </div>
                          ))}
                      </>
                    ))}

                    {uaFilters.map((recipient, index) => (
                      <>
                        {recipient.type === typesFilter &&
                          (recipient.deniedGroups.length > 0 ? (
                            <div className="tab-groups">
                              <span>Groupes exclus : </span>
                              <div className="tag">
                                {recipient.deniedGroups.map((group, index) => (
                                  <span key={index} className="denied-groups">
                                    {group.name}
                                  </span>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className="tab-groups">
                              <span>Groupes exclus : </span>
                              <div className="not-found-msg">
                                <span>Aucun groupe n'est sélectionné</span>
                              </div>
                            </div>
                          ))}
                      </>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </TTPModal>
      </div>
    );
  }
}

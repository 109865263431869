import {
  TTP_EMAILING_URL,
  TTP_BLOG_URL,
  TTP_BE_ACCOUNTANTS_BLOG,
  TTP_FFF_BLOG,
  TTP_DEGANDPARTNERS_BLOG,
} from "Config";
import { getDate, getNameByLanguage, purifyString } from "utils";
import _ from "i18n";
import { RESIZE_ARTICLE_COVER, TEMPLATE_TYPES } from "../constants";
import { ucFirst } from "./common";
import { store } from "..";
import { TTP_API_URL } from "../config";

export const formatArticle = (article) => {
  let {
    id,
    title,
    main_media,
    albums,
    url,
    introduction,
    tags,
    language,
    organization,
    pages,
    externalUrl,
    category,
    publishedAt,
    tagsArray,
    readTime,
  } = article;
  const currentClient = store.getState().auth.currentClient;
  const isUaNewsletter =
    store.getState().currentCampaign.uaNewsletter.isUaNewsletter;
  let propUrl = TTP_BLOG_URL + language + "/";

  if (currentClient && currentClient.id) {
    if (currentClient.id == 8) {
      propUrl = TTP_BE_ACCOUNTANTS_BLOG;
    } else if (currentClient.id == 9) {
      propUrl = TTP_FFF_BLOG + language + "/";
    } else if (currentClient.id == 4) {
      propUrl = TTP_DEGANDPARTNERS_BLOG + language + "/";
    }
  }

  const articleUrl =
    externalUrl && externalUrl.length > 0
      ? externalUrl
      : `${propUrl}article/${url}/${id}?lng=${language}&utm_source=newsletter&utm_medium=email&utm_campaign=nl`;
  const articleCover = getArticleCover(main_media, albums);

  let arrayTags = [];
  let articleTags = [];
  if (tags && tags.length > 0) {
    let articleTagsArray = Array.isArray(tags) ? tags : tagsArray;
    arrayTags = prepareTags(articleTagsArray, language, propUrl, organization);
    articleTags = arrayTags
      .map(
        ({ tagUrl, tagName }) =>
          `<a style="text-decoration: none; color: rgba(121,121,121)" href="${tagUrl}">#${tagName}</a>`,
      )
      .join(", ");
  }

  let pageTitle = pages && pages.length > 0 ? pages[0].title : null;

  var sd = new Date(publishedAt);

  if (sd === "Invalid Date" || sd.toString() === "Invalid Date") {
    sd = new Date(publishedAt.replace(/-/g, "/"));
  }

  const tmp = {
    date:
      sd.getFullYear() +
      "-" +
      (sd.getMonth() + 1) +
      "-" +
      sd.getDate() +
      " " +
      sd.getHours() +
      ":" +
      sd.getMinutes() +
      ":" +
      sd.getSeconds(),
    timezone: "UTC",
  };

  const {
    authorName,
    authorAvatar,
    authorHeadline,
    authorProfession,
    organisationName,
    articleType,
    articleTypeColor,
  } = isUaNewsletter ? getAuthorsChainsData(article) : getAuthorData(article);
  return {
    id,
    title: purifyString(title),
    articleUrl,
    introduction: purifyString(introduction),
    cover: articleCover,
    tags: articleTags,
    tagsArray: arrayTags,
    articlePage: pageTitle,
    displayPageTitle: pageTitle ? "block" : "none",
    authorName,
    authorAvatar: authorAvatar
      ? `${authorAvatar}`
      : authorName
        ? `https://ui-avatars.com/api/?background=ccc&color=fefefe&name=${authorName.replace(
            / /g,
            "+",
          )}`
        : TTP_EMAILING_URL + "/img/theme/no-theme.png",
    hideAvatar: !authorAvatar && !authorName ? "none" : "inline-block",
    authorHeadline,
    displayHeadline:
      authorHeadline && authorHeadline.length > 0 ? "block" : "none",
    ReadMore: _("Read more", language),
    category: getNameByLanguage(category, language),
    color: category.colorCode,
    quotation: "Lorem ipsum set amet dolor",
    publishedAt: getDate(tmp, language),
    type: TEMPLATE_TYPES["NEWS_LAYOUT"],
    organization,
    language,
    main_media,
    albums,
    authorProfession,
    organisationName,
    articleType,
    articleTypeColor,
    readTime: (readTime ? readTime : 1).toString(),
  };
};

const prepareTags = (tags, language, propUrl, organization) => {
  const { id, url } = organization;
  if (!tags || tags.length === 0) {
    return "";
  }
  return tags.reduce((acc, tag) => {
    const tagName = tag[`name${ucFirst(language)}`];
    if (!tagName || tagName === "undefined") return acc;
    const tagUrl = `${propUrl}${language}/community/${url}/${id}/tag/${tag.id}`;
    if (tagName) {
      acc.push({ id: tag.id, tagName, tagUrl });
    }
    return acc;
  }, []);
};

const getAuthorTitle = (user, isAvatar) => {
  if (isAvatar) {
    const condition =
      (user.firstName && user.firstName.length > 0) ||
      (user.lastName && user.lastName.length > 0);
    return condition
      ? (user.firstName ? user.firstName + " " : "") +
          (user.lastName ? user.lastName : "")
      : user.company;
  } else {
    const condition =
      user.signature && user.signature.title && user.signature.title.length > 0;
    return condition
      ? user.signature.title
      : user.firstName + " " + user.lastName;
  }
};

export const getAuthorData = (article) => {
  let { avatars, pages, author } = article;
  let enabledAvatar = null;
  let enabledAuthor = null;
  let enabledAuthorList = null;

  if (author && author.length > 0) {
    enabledAuthorList = author.filter((a) => a.enableAvatar === true);
    enabledAuthor =
      enabledAuthorList.length > 0 ? enabledAuthorList[0] : author[0];
  }

  if (!enabledAuthorList?.length && avatars && avatars.length > 0) {
    enabledAvatar = avatars[0];
  }

  let authorAvatar = null;
  let authorName = "";
  let authorHeadline = "";
  if (enabledAvatar) {
    authorAvatar = enabledAvatar.avatarUrl;
    authorName = getAuthorTitle(enabledAvatar, true);
    authorHeadline = enabledAvatar.headline;
  } else if (enabledAuthor) {
    authorAvatar = enabledAuthor.avatarUrl;
    authorName = getAuthorTitle(enabledAuthor);
    authorHeadline =
      enabledAuthor.signature.head &&
      enabledAuthor.signature.head.length > 0 &&
      enabledAuthor.signature.head;
  } else if (pages && pages[0] && pages[0].sharedBy) {
    authorAvatar = pages[0].sharedBy.avatarUrl;
    authorName = getAuthorTitle(pages[0].sharedBy);
  }
  return {
    authorAvatar,
    authorName,
    authorHeadline,
  };
};

export const getArticleCover = (mainMedia, albums) => {
  let url = null;
  const FAKE_FEED_COVER =
    "https://s3.tamtam.pro/prod/emailing/media/4/b5640f1490385fd0296db9b35ff742fbba1eff6a.png";

  if (mainMedia && mainMedia.fullReducedWebPath) {
    url = mainMedia.fullReducedWebPath;
  } else if (mainMedia && mainMedia.fullCroppedWebPath) {
    url = mainMedia.fullCroppedWebPath;
  } else if (mainMedia && mainMedia.fullMediaUrl) {
    url = mainMedia.fullMediaUrl;
  } else if (
    albums &&
    albums.length > 0 &&
    albums[0].medias &&
    albums[0].medias.length > 0 &&
    albums[0].medias[0].fullMediaUrl
  ) {
    url = albums[0].medias[0].fullMediaUrl;
  }
  return url ? `${url}${RESIZE_ARTICLE_COVER}` : FAKE_FEED_COVER;
};

export const getAuthorsChainsData = (article) => {
  let { author, chains } = article;
  let enabledAuthor = null;
  let enabledAuthorList = null;

  if (author && author.length > 0) {
    enabledAuthorList = author.filter((a) => a.enableAvatar === true);
    enabledAuthor = enabledAuthorList.length > 0 ? enabledAuthorList[0] : null;
  }

  let authorAvatar = null;
  let authorName = "";
  let authorHeadline = "";
  let authorProfession = "";
  let organisationName = "";
  let articleType = "";
  let articleTypeColor = "";
  if (enabledAuthor) {
    authorAvatar = enabledAuthor.avatarUrl;
    authorName = getAuthorTitle(enabledAuthor);
    authorProfession = author[0].signature.head;
    organisationName = article.organization.abbreviation;
    articleType = article.type.nameFr;
    articleTypeColor = article.type.colorCode;
  } else if (chains && chains.length > 0) {
    const lng = store.getState().currentCampaign.email.language;
    const nameAttr = `name${lng?.charAt(0).toUpperCase() + lng?.slice(1)}`;
    const mediaChainSelected = chains[0].mediaChain?.find(
      (el) => el.language === lng,
    );
    authorAvatar = mediaChainSelected.avatarUrl
      ? mediaChainSelected.avatarUrl
      : TTP_API_URL + "/" + mediaChainSelected.avatar;
    authorName = chains[0][nameAttr];
  }
  return {
    authorAvatar,
    authorName,
    authorHeadline,
    authorProfession,
    organisationName,
    articleType,
    articleTypeColor,
  };
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentNavPage, setCurrentDndTheme } from "actions";
import { recoverInvalidContactClientEmails } from "../../actions/thunks/campaign";

import EditorSelectorTabSend from 'startup/EditorSelectorTabSend';
import ThemePreview from 'editor/ThemePreview';
import { STEPS } from 'Common';
import PageHeader from 'common/PageHeader';

const mapStateToProps = (store) => ({
  step: store.params.step,
  content: store.themes.content,
  currentOrganization : store.auth.currentClient.id,
});

const mapDispatchToProps = (dispatch) => ({
  recoverInvalidContactClientMails: (orgId) => dispatch(recoverInvalidContactClientEmails(orgId)),
  setCurrentNavPage: (page) => dispatch(setCurrentNavPage(page)),
});
@connect(mapStateToProps, mapDispatchToProps)

export default class ChoicePage extends Component {

  componentDidMount() {
    const { currentOrganization, setCurrentNavPage, recoverInvalidContactClientMails } = this.props;
    setCurrentNavPage("CREATE_CAMPAIGN");
    recoverInvalidContactClientMails(currentOrganization);
  }

  onTypeSelect = (type) => {
    if (type === "/drag-drop") {
      this.props.setCurrentDndTheme(null);
    }
    this.props.history.push(type);
  }

  render() {
    const { content, step ,currentPage,handleSetCurrentPage} = this.props;
    const showPreview = step == STEPS["THEME_PREVIEW"]

    return (
      <div>
        {showPreview && <ThemePreview dispatch={this.props.dispatch} content={content} previous={{ step: STEPS["CREATE_CAMPAIGN"], url: "/campaign/selector" }} />}
        <div id="home-page" className={`campaign-type__selector ${showPreview ? "hide" : ""}`}>
          <PageHeader header="Create email campaigns" subHeader="Create, manage and send your email campaigns ." icon="EDIT_EMAIL" />
          <EditorSelectorTabSend onTypeSelect={this.onTypeSelect}
          currentPage={currentPage}
          handleSetCurrentPage={handleSetCurrentPage}
          />
        </div>
      </div>
    )
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import TTPSelect from "../../common/TTPSelect";
import _ from "i18n";
import TeamsFeedbackFetching from "../../fetching/TeamsFeedbackFetching";
import NotFound from "../../notFound/NotFound";
import { setCurrentEmailCampaignData, setProgrammedDate, initApprovalTeams } from "actions";
import { fetchApprovalFeedback, fetchApprovalTeams } from "thunks";
import { stringToColor } from "../../../services/colors";
import StepsFooter from "./StepsFooter";
import TTPCard from "../../common/card/TTPCard";
import { ucFirst } from "../../../services/common";
import CheckBoxBtn from "../../common/CheckBoxBtn";
import { TeamOption } from "../../campaign/test/TeamOption";
import InlineToolbarEditor from "../../campaign/test/InlineToolbarEditor";
import moment from "moment/moment";
import InputMoment from "input-moment";

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
@connect((store) => {
  return {
    currentStep: store.currentCampaign.email.currentSideBar === "APPROVAL",
    campaignId: store.currentCampaign.email.id,
    isCampaignApproved: store.approval.list.isCampaignApproved,
    fetching: store.approval.list.fetching,
    approvals: store.approval.list.items,
    lastTestversion: store.currentCampaign.email.lastTestversion,
    mandatoryTest: store.auth.currentSetting.mandatoryTest,
    autoSend: store.auth.currentSetting.autoSend,
    approvalTeams: store.currentCampaign.email.approvalTeams,
    teams: store.approval.teams.items,
    scheduledDeliveryAt: store.currentCampaign.email.scheduledDeliveryAt,
    programmedDate: store.currentCampaign.uaNewsletter.programmedDate,
    userEmails: store.auth.user.email,
    autoSendApprovalNl: store.currentCampaign.email.autoSendApprovalNl,
    auth: store.auth,
    testingDisabled: !store.currentCampaign.email.fromEmailValid,
  };
})
export default class ApprovalStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      version: props.lastTestversion,
      sendToApprovers: true,
      showDetails: false,
      scheduleCampaign: false,
      showInputMoment: false,
      scheduledDeliveryAtState: this.props.scheduledDeliveryAt,
    };

    this.handleVersionChange = (version) => this.setState({ version });
    this.toogleDetails = () =>
      this.setState({ showDetails: !this.state.showDetails });
    this.handleCancelSchedulingDelivery =
      this.handleCancelSchedulingDelivery.bind(this);
    this.toggleScheduleBlock = () => {
      this.setState({ scheduleCampaign: !this.state.scheduleCampaign });
    };
    this.toggleInputMoment = (showInputMoment) =>
      this.setState({ showInputMoment });
  }

  handleApprovalClick() {
    let { onConfirm } = this.props;
    const { sendToApprovers } = this.state;

    if (!sendToApprovers) {
      return;
    }
    let sentOptions = { sendToApprovers };
    onConfirm(sentOptions);
  }

  handleSingleApprovalClick(approvalId) {
    if (!approvalId) {
      return;
    }

    let sentOptions = { sendToApprovers: true, approverId: [approvalId] };

    this.props.onConfirm(sentOptions);
  }

  handleApprovalTeamsChange = (teams) => {
    const { dispatch } = this.props;
    dispatch(setCurrentEmailCampaignData({ approvalTeams: teams }));
  };

  handleCancelSchedulingDelivery() {
    const { dispatch } = this.props;
    dispatch(setCurrentEmailCampaignData({ scheduledDeliveryAt: "" }));
    this.setState({ scheduledDeliveryAtState: "" });

    this.toggleScheduleBlock();
  }

  handleScheduledDeliveryAtChange = (scheduledDeliveryAt) => {
    const { dispatch } = this.props;
    dispatch(setCurrentEmailCampaignData({ scheduledDeliveryAt }));
    dispatch(setProgrammedDate(null));
    this.setState({ scheduledDeliveryAtState: scheduledDeliveryAt });
  };

  componentDidMount() {
    if (
      this.props.programmedDate != null &&
      moment(this.props.programmedDate).isValid()
    ) {
      this.setState({ scheduleCampaign: true });
    }
    this.props.dispatch(fetchApprovalTeams());
  }

  componentWillMount() {
    let { userEmails, dispatch } = this.props;
    let mainUserEmail = "";
    dispatch(initApprovalTeams());
    for (let i = 0; i < userEmails.length; i++) {
      if (userEmails[i]["main"] === 1) {
        mainUserEmail = userEmails[i];
        break;
      }
    }
    if (!mainUserEmail) {
      mainUserEmail = userEmails[0];
    }
    dispatch(fetchApprovalTeams());
    this.setState({ mainUserEmail });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lastTestversion != this.props.lastTestversion &&
      this.state.version != this.props.lastTestversion
    ) {
      this.setState({ version: this.props.lastTestversion });
    }

    if (this.props.teams !== prevProps.teams) {
      this.props.teams.map((team) => {
        if (team.isDefault) {
          this.props.dispatch(
            setCurrentEmailCampaignData({ approvalTeams: team.id }),
          );
        }
      });
    }
    if (this.props.scheduledDeliveryAt !== prevProps.scheduledDeliveryAt) {
      this.setState({
        scheduledDeliveryAtState: this.props.scheduledDeliveryAt,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { campaignId, isCampaignApproved } = nextProps;
    if (
      nextProps.currentStep &&
      campaignId &&
      (nextState.version != this.state.version || !this.props.currentStep)
    ) {
      nextProps.dispatch(
        fetchApprovalFeedback({
          campaignId,
          version: nextState.version,
        }),
      );
    }
    if (isCampaignApproved !== this.props.isCampaignApproved) {
      nextProps.dispatch(
        setCurrentEmailCampaignData({ approved: isCampaignApproved }),
      );
    }
  }

  renderVersionOptions() {
    let { version } = this.state;
    let { lastTestversion } = this.props;

    let options = [];

    for (let i = 1; i <= lastTestversion; i++) {
      options.push({ id: i + "", label: `Version ${i}` });
    }

    return (
      <TTPSelect
        simple={true}
        notClearable={true}
        values={version}
        placeholder={_("version")}
        options={options}
        onChange={this.handleVersionChange}
        cssClass="version-select"
      />
    );
  }

  renderTeamFeedback() {
    let feedbacks = this.props.approvals || {};
    return Object.values(feedbacks).map((team) => {
      let initial = (team.name && team.name.substring(0, 1)) || "";

      let approvalsCount = team.members
        ? team.members.reduce((count, member) => {
            if (member.decision === "ACCEPT") {
              count++;
            }
            return count;
          }, 0)
        : 0;

      let totalApprovers = team.members ? team.members.length : 0;

      return (
        <div
          key={`team-${team.id}`}
          className="team-list-item team-list-item--open"
        >
          <div className="row align-middle team-header">
            <div className="small-1">
              <span
                className="team-avatar"
                style={{ background: stringToColor(team.name) }}
              >
                {initial}
              </span>
            </div>
            <div className="small-7">
              <span className="param-label">{team.name}</span>
              <p>{team.description}</p>
            </div>
            <div className="small-4 team-header--members">
              <div>
                {_("the approval threshold")} : <b>{team.minApprovers}</b>
              </div>
              <div>
                {_("acceptedApproval")}
                {" :"} &nbsp;
                <b>
                  {" "}
                  {approvalsCount}
                  {"/"}
                  {totalApprovers}
                </b>
              </div>
            </div>
          </div>
          <div className="team-setting">
            {this.renderMembersFeedback(team.members || [])}
          </div>
        </div>
      );
    });
  }

  renderMembersFeedback(members) {
    return (
      <div style={{ columnCount: 3 }}>
        {members.map((member) => (
          <TTPCard
            member={member}
            key={`approval-${member.id}`}
            handleSingleApprovalClick={() => this.handleSingleApprovalClick(member.email)}
          />
        ))}
      </div>
    );
  }

  render() {
    let {
      currentStep,
      approvals,
      fetching,
      disabled,
      mandatoryTest,
      autoSend,
      approvalTeams,
      teams,
      programmedDate,
      scheduledDeliveryAt,
      autoSendApprovalNl,
      auth,
      plan,
    } = this.props;
    let teamsBlock = null;
    if (fetching) {
      teamsBlock = <TeamsFeedbackFetching />;
    } else if (
      Object.keys(approvals || {}) &&
      Object.keys(approvals || {}).length > 0
    ) {
      teamsBlock = this.renderTeamFeedback();
    } else {
      teamsBlock = <NotFound />;
    }

    let {
      showDetails,
      sendToApprovers,
      scheduleCampaign,
      showInputMoment,
      scheduledDeliveryAtState,
    } = this.state;

    var isApproval = approvals && Object.keys(approvals).length > 0;

    return (
      <div id="send-tabs__tab" className={`${!currentStep && "hide"}`}>
        <div className="tab">
          <h4 className="tab-header">{_("send_approval")}</h4>
          <div className="row">
            {(auth.currentClient?.id === 4 || plan === "PERSONALIZED") && (
              <div className="teams small-6 columns-padding">
                <div className="small-12 tab-label">
                  {ucFirst(_("send to approval teams"))}
                  <span className="see-more" onClick={this.toogleDetails}>
                    {_(showDetails ? "see less" : "see more")}
                    <i
                      className={`icon ${
                        showDetails ? "icon-arrow-up" : "icon-arrow-down"
                      }`}
                    />
                  </span>
                </div>
                {showDetails && (
                  <div className="columns approvers-block">
                    <div>
                      - {_("approval is required")} :{" "}
                      <b>{mandatoryTest ? _("yes") : _("no")}</b>
                    </div>
                    <div>
                      - {_("automatic sending")} :{" "}
                      <b>{autoSend ? _("yes") : _("no")}</b>
                    </div>
                  </div>
                )}

                <div className="small-12 flex-container align-middle m-t-xs m-r-xs ">
                  <TTPSelect
                    simple={true}
                    values={approvalTeams}
                    placeholder={_("Select approval teams")}
                    options={teams.filter(
                      (team) => team.members && team.members.length,
                    )}
                    onChange={this.handleApprovalTeamsChange}
                    cssClass={
                      (!approvalTeams || !approvalTeams.length) && "empty-box"
                    }
                    customOption={TeamOption}
                  />
                </div>
              </div>
            )}

            <div className="columns small-6 columns-padding">
              <span className="tab-label">{ucFirst(_("Add a comment"))}</span>
              <div className="small-12">
                <InlineToolbarEditor />
              </div>
            </div>

            {sendToApprovers && scheduledDeliveryAt && (
              <div className="small-12 row columns auto-send-block">
                <div
                  className={`item ${
                    (autoSendApprovalNl ? "checked" : "",
                    sendToApprovers ? "large_block" : "")
                  }`}
                  onClick={() =>
                    this.props.dispatch(
                      setCurrentEmailCampaignData({
                        autoSendApprovalNl: !autoSendApprovalNl,
                      }),
                    )
                  }
                >
                  <CheckBoxBtn
                    onChange={() =>
                      this.props.dispatch(
                        setCurrentEmailCampaignData({
                          autoSendApprovalNl: !autoSendApprovalNl,
                        }),
                      )
                    }
                    label={_("auto_send_approved_newsletter_message")}
                    checked={autoSendApprovalNl}
                    labelClass={autoSendApprovalNl && "checked"}
                  />
                </div>
              </div>
            )}

            <div className="small-12 row columns send-buttons align-center">
              <div
                className={`send-buttons__item ${
                  !scheduleCampaign ? "checked" : ""
                }`}
                onClick={this.handleCancelSchedulingDelivery}
              >
                <CheckBoxBtn
                  onChange={this.handleCancelSchedulingDelivery}
                  label={_("Send your campaign immediately")}
                  checked={!scheduleCampaign}
                  labelClass={!scheduleCampaign && "checked"}
                />
              </div>
              <div
                className={`send-buttons__item ${
                  scheduleCampaign ? "checked" : ""
                }`}
                onClick={this.handleCancelSchedulingDelivery}
              >
                <CheckBoxBtn
                  onChange={this.toggleScheduleBlock}
                  label={_("schedule_sending_campaign")}
                  checked={scheduleCampaign}
                  labelClass={scheduleCampaign && "checked"}
                />
              </div>

              <div
                className={`row ${
                  !(scheduleCampaign || scheduledDeliveryAtState) && "hide"
                }`}
                style={{ display: "inline-block", width: "100%" }}
              >
                <input
                  placeholder={_("delivery date")}
                  type="text"
                  value={
                    programmedDate && moment(programmedDate).isValid()
                      ? programmedDate.format(DATE_FORMAT)
                      : (scheduledDeliveryAtState &&
                          moment(scheduledDeliveryAtState).format(
                            DATE_FORMAT,
                          )) ||
                        ""
                  }
                  readOnly
                  className={
                    scheduledDeliveryAtState ? "filled-box" : "empty-box"
                  }
                  onFocus={() => this.toggleInputMoment(true)}
                />
                <a
                  className="clear text-uppercase"
                  onClick={this.handleCancelSchedulingDelivery}
                >
                  {_("cancel")}
                </a>
                {showInputMoment && (
                  <div className="input-moment">
                    <InputMoment
                      moment={
                        programmedDate && moment(programmedDate).isValid()
                          ? programmedDate
                          : scheduledDeliveryAtState || moment()
                      }
                      onChange={this.handleScheduledDeliveryAtChange}
                      onSave={this.toggleInputMoment}
                      prevMonthIcon="icon-arrow-left"
                      nextMonthIcon="icon-arrow-right"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.props.lastTestversion && isApproval && (
            <>
              <h4 className="tab-header">{_("approvers's feedback")}</h4>
              <div className="row">
                <div className="small-2">{this.renderVersionOptions()}</div>
              </div>
              <div className="row small-11 team-list">{teamsBlock}</div>
            </>
          )}
        </div>
        <StepsFooter
          disabled={disabled && mandatoryTest}
          handleApprovalClick={this.handleApprovalClick.bind(this)}
          testingEnabled={true}
          plan={plan}
        />
      </div>
    );
  }
}

const initialState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null
};

const initialStateForArticles = {
  items: [],
  nbResult: 0,
  fetching: false,
  fetched: false
};

export const newslettersUAArticlesReducer = (
  state = initialStateForArticles,
  action
) => {
  switch (action.type) {
    case "INIT_ARTICLES": {
      return initialStateForArticles;
      break;
    }

    case "FETCH_NEWSLETTER_UA_ARTICLES_PENDING": {
      return {
        ...state,
        fetching: true
      };
      break;
    }

    case "FETCH_NEWSLETTER_UA_ARTICLES_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      const { items } = state;
      let newItems = Array.isArray(items) ? [...items] : [];
          if (Array.isArray(data)) {
        newItems.push(...data);
      }
    
      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        nbResult
      };
    }
    

    case "FETCH_NEWSLETTER_UA_ARTICLES_REJECTED": {
      const { nbResult, items } = state;
      const newItems = items;

      let error = action.payload;
      if (error && error.response && error.response.status === 404) {
        error = {
          title: error.response.data.title,
          code: 404
        };
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: false,
        nbResult,
        error
      };
      break;
    }

    case "FETCH_NEWSLETTER_UA_ARTICLES_BY_PERIOD_PENDING": {
      return {
        ...state,
        fetching: true
      };
      break;
    }

    case "FETCH_NEWSLETTER_UA_ARTICLES_BY_PERIOD_FULFILLED": {
      const { data } = action.payload.data;

      return {
        ...state,
        items: data,
        fetching: false,
        fetched: true,
        nbResult: data.articles.length
      };
      break;
    }

    case "FETCH_NEWSLETTER_UA_ARTICLES_BY_PERIOD_REJECTED": {
      let { items } = state;
      let newItems = items;

      let error = action.payload;
      if (error && error.response && error.response.status === 404) {
        error = {
          title: error.response.data.title,
          code: 404
        };
      }
      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: false,
        error
      };
      break;
    }
    case "UPDATE_IS_USED_ARTICLES_NEWSLETTER_PENDING": {
      return {
        ...state,
        fetching: true
      };
      break;
    }
    case "UPDATE_IS_USED_ARTICLES_NEWSLETTER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true
      };
      break;
    }
    case "UPDATE_IS_USED_ARTICLES_NEWSLETTER_REJECTED": {
      let error = action.payload;
      if (error && error.response && error.response.status === 404) {
        error = {
          title: error.response.data.title,
          code: 404
        };
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error
      };
      break;
    }
    default:
      return state;
  }
};
